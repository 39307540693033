.title {
  color: #91ac27;
  font-size: 36px;
  letter-spacing: 1.5px;
  line-height: 1em;
  text-transform: capitalize;
  font-family: "Playfair Display", serif;
}

.subheading {
  color: #91ac27;
}

.page-heading {
  margin-bottom: 50px;
  margin-top: 80px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 10px;
  width: 100% !important;
}

.react-datepicker__input-container input {
  background-color: lightblue;
  color: black;
}

p {
  width: 94%;
}