body {
  margin: 0;
  font-family: "Playfair Display", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1;
  color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.twin-btn {
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #91ac27;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(145, 172, 39, 0.7);
  box-shadow: 0px 0px 7px 2px rgba(145, 172, 39, 0.7);
  margin: 10px;
  cursor: pointer; /* Change the cursor to a pointer on hover for all buttons for better UX */
}

.twin-btn:hover {
    background-color: #cbe076; /* Change the background color on hover for all buttons */
}

.settings-container {
  display: flex;
  flex-direction: row;
}

.settings-controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
  padding: 20px;
}

.log-container {
  margin-top: 0px;
  margin-left: 30px;
  overflow: auto;
  height: 400px;
  width: 600px;
  border: 1px solid darkgray;
}

table, th, td {
  border: 1px solid;
  border-color: #427e0b;
  border-radius: 3px;
}

tr :hover{
  background-color: lightblue;
}

.horizontal-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.horizontal-element {
  padding: 10px;
}
